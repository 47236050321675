// import React from "react";
// import FbReview from "assets/svg/fbreview";
// import TrustPilot from "assets/svg/trustpilot";
// import FeatureCustomer from "assets/svg/featuredcustomer";
// // import ReviewSvg from "assets/images/review_stars.svg";
// import ReviewCardsDesign2 from "../reviewcard/design2";
// import OptimizedImage from "hooks/OptimizedImage";

// export default function Reviews({
//   backgroundCol,
//   head,
//   content,
//   headB,
//   socialIcons,
//   headH2,
//   textcol,
//   socialIconsVisible,
// }) {
//   return (
//     <section
//       className={`${backgroundCol ? backgroundCol : "bg-midBlue"} py-20`}
//     >
//       <div className="container mx-auto px-4 max-w-6xl">
//         {head ? (
//           head
//         ) : (
//           // <h2
//           //   className={`${
//           //     backgroundCol ? "text-black" : "text-gray"
//           //   } text-3xl md:text-4xl text-center font-secondary mb-6`}
//           // >
//           //   What Our <span className="text-orange">Clients</span> are Saying
//           //   About Us
//           // </h2>
//           <h2
//             className={`${textcol} text-3xl md:text-4xl text-center font-secondary mb-6`}
//           >
//             What Our <span className="text-orange">Clients</span> are Saying
//             About Us
//           </h2>
//         )}
//         {headB ? (
//           <h1 className="text-white font-semibold text-5xl text-center font-secondary">
//             <span className="text-orange">Customer </span>Reviews
//           </h1>
//         ) : null}
//         {headH2 ? (
//           <h2 className="text-white font-semibold text-5xl text-center font-secondary">
//             <span className="text-orange">Customer </span>Reviews
//           </h2>
//         ) : null}
//         {content ? (
//           <p className="text-white text-center font-light py-8">
//             This page shows the reviews of our 95,000+ clients, which range from
//             private individuals to small and medium businesses to large
//             corporations to national and local governments. We're rated 4.9 out
//             of 5 stars in our overall effort to broaden the horizons of our
//             clients.
//           </p>
//         ) : null}
//         <div className="flex flex-row justify-center mb-8 mt-10">
//           <OptimizedImage
//             src="https://tomedes.gumlet.io/assets/images/review_stars.svg"
//             alt="review star"
//             className="mt-5 mb-2 w-[343px] sm:w-[403px] h-[49px] sm:h-[59px]"
//           />
//         </div>
//         <p className={`font-secondary text-sm ${textcol} text-center`}>
//           4.9 out of 5 stars (1,798 overall reviews)
//         </p>
//         <div className="mx-auto pt-10 pb-8">
//           {socialIcons ? (
//             socialIcons
//           ) : (
//             <div className="grid gap-4 grid-cols-1 place-items-center lg:grid-cols-3 lg:gap-0 lg:w-9/12 mx-auto">
//               <FbReview />
//               <TrustPilot />
//               <FeatureCustomer />
//             </div>
//           )}
//         </div>
//         <ReviewCardsDesign2 backgroundCol="bg-white" />
//       </div>
//     </section>
//   );
// }
import React from "react";
import FbReview from "assets/svg/fbreview";
import TrustPilot from "assets/svg/trustpilot";
import FeatureCustomer from "assets/svg/featuredcustomer";
import ReviewCardsDesign2 from "../reviewcard/design2";
import OptimizedImage from "hooks/OptimizedImage";

export default function Reviews({
  backgroundCol,
  head,
  content,
  headB,
  socialIconsVisible, // Add a new prop to control visibility
  headH2,
  textcol,
}) {
  return (
    <section
      className={`${backgroundCol ? backgroundCol : "bg-midBlue"} py-20`}
    >
      <div className="container mx-auto px-4 max-w-6xl">
        {head ? (
          head
        ) : (
          <h2
            className={`${textcol} text-3xl md:text-4xl text-center font-secondary mb-6`}
          >
            What Our <span className="text-orange">Clients</span> are Saying
            About Us
          </h2>
        )}
        {headB ? (
          <h1 className="text-white font-semibold text-5xl text-center font-secondary">
            <span className="text-orange">Customer </span>Reviews
          </h1>
        ) : null}
        {headH2 ? (
          <h2 className="text-white font-semibold text-5xl text-center font-secondary">
            <span className="text-orange">Customer </span>Reviews
          </h2>
        ) : null}
        {content ? (
          <p className="text-white text-center font-light py-8">
            This page shows the reviews of our 95,000+ clients, which range from
            private individuals to small and medium businesses to large
            corporations to national and local governments. We're rated 4.9 out
            of 5 stars in our overall effort to broaden the horizons of our
            clients.
          </p>
        ) : null}
        <div className="flex flex-row justify-center mb-8 mt-10">
          <OptimizedImage
            src="https://tomedes.gumlet.io/assets/images/review_stars.svg"
            alt="review star"
            className="mt-5 mb-2 w-[343px] sm:w-[403px] h-[49px] sm:h-[59px]"
          />
        </div>
        <p className={`font-secondary text-sm ${textcol} text-center`}>
          4.9 out of 5 stars (1,798 overall reviews)
        </p>
        {socialIconsVisible ? null : (
          <div className="mx-auto pt-10 pb-8">
            <div className="grid gap-4 grid-cols-1 place-items-center lg:grid-cols-3 lg:gap-0 lg:w-9/12 mx-auto">
              <FbReview />
              <TrustPilot />
              <FeatureCustomer />
            </div>
          </div>
        )}
        <ReviewCardsDesign2 backgroundCol="bg-white" />
      </div>
    </section>
  );
}
