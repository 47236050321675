import React from "react";

export default function ContentService() {
  return (
    <div className="bg-midBlue text-[#E6E6E6]" id="content-writing">
      <div className="content-container w-9/12 flex font-secondary flex-col justify-start mx-auto py-10">
        <p className="text-[18px] font-opensans py-[100px]">
          We deliver high quality, notarized translation services that meet the
          rigid requirements of any government agency or international
          organization. We work with the best translators to provide accurate
          document translations promptly anywhere in the world.
        </p>
        <h2 className="text-white text-[35px] pb-[40px] ">
          Network of Expert Writers
        </h2>
        <p className="font-opensans">
          We scour the planet for talented, enthusiastic writers who can produce
          consistently high-quality content for clients from a wide range of
          sectors. Outstanding writing skills, specialist knowledge and
          attention to detail are all essential for members of our talented
          content writing community.
        </p>
        <h2 className="textt-white text-[35px] pb-[40px] pt-[100px]">
          Quality Guarantee
        </h2>
        <p className="font-opensans">
          We deliver only the very highest quality content, no matter which
          language it’s in. Our clients rely on us to meet their content needs
          perfectly, each and every time and we do so with confidence. We even
          provide a one-year guarantee for all the content we provided, for the
          ultimate peace of mind.
        </p>
        <h2 className="textt-white text-[35px] pb-[40px] pt-[100px]">
          Areas of Expertise
        </h2>
        <p className="font-opensans">
          Our areas of expertise are wide-ranging. We shape our services to meet
          the needs of our clients, so can provide content writers for
          everything from general copy to more niche subject areas.
        </p>
        <h2 className="textt-white text-[35px] pb-[40px] pt-[100px]">
          Multilingual Content
        </h2>
        <p className="font-opensans">
          Whichever languages you need your content in, we can help. Our native
          content writers cover a huge range of languages, providing local
          knowledge and insights for clients around the world.
        </p>
        <h2>Our Content Writing Services</h2>
        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          Article Writing
        </h3>
        <p className="font-opensans">
          Whatever your subject matter, we can provide insightful, engaging
          articles that bring your topic to life.
        </p>
        <a href="">LEARN MORE</a>
        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          Blog Posts
        </h3>
        <p className="font-opensans">
          Let us engage your audience through informed, well-written blog posts
          that support the growth of your brand.
        </p>
        <a href="">LEARN MORE</a>
        <h3 className="text-white text-[35px] pb-[40px] pt-[100px]">
          Website Content
        </h3>
        <p className="font-opensans">
          Our website writing service can furnish you with a professionally
          written, engaging website that acts as your window to the world.
        </p>
        <h3 className="textt-white text-[26px] pb-[40px] pt-[100px]">
          Technical Writing
        </h3>
        <p className="font-opensans">
          We provide content writers covering a range of technical topics and
          are ready to craft the perfect content for your niche.
        </p>

        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          White Paper
        </h3>
        <p className="font-opensans">
          Detailed report researching and writing is a fine art, and thankfully
          one that our content writers are entirely comfortable with.
        </p>

        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          Social Media
        </h3>
        <p className="font-opensans">
          Our social media content creation team is at the forefront of engaging
          with customers through a wide range of social networks.
        </p>
        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          Metadata{" "}
        </h3>
        <p className="font-opensans">
          We can provide the perfect metadata to suit your needs, no matter how
          specialist the subject matter of your content may be.
        </p>
        <h3 className="text-white text-[26px] pb-[40px] pt-[100px]">
          Creative Writing
        </h3>
        <p className="font-opensans">
          Our writers love to unleash their creativity, so will welcome the
          opportunity to boost your brand through creative writing.
        </p>
      </div>
    </div>
  );
}
