import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import BBC from "assets/review/bbc.svg";

export default function ReviewCardsDesign2({ backgroundCol }) {
  return (
    <div>
      <div className="mainContainer w-full flex flex-col lg:flex-row lg:flex-grow-0 mx-auto justify-center text-white py-[16px] lg:gap-x-6 ">
        <a
          href="https://www.trustpilot.com/users/60db02325f811c001128d575"
          className={`cardOne ${
            backgroundCol ? `${backgroundCol} text-black` : "bg-boxColor"
          } h-[450px] rounded-2xl p-6 lg:w-22REM w-full  relative mb-6 lg:mb-0 shadow-lg`}
          target="_blank"
        >
          <p className=" font-opensans font-semibold text-base">
            "Speedy return & <br /> reasonably priced"
          </p>
          <p className="mt-6 font-opensans  font-light text-light text-sm tracking-wider leading-[22px]">
            A translation company well equipped with knowledge of hundreds of
            different languages - speedy return & reasonably priced.
          </p>
          <div className="name absolute bottom-0 mb-4 pb-6">
            {backgroundCol ? (
              <img src={BBC} alt="bbc" className="w-[85px] h-6 mb-2" />
            ) : (
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/bbc_logo.svg"
                alt="bbc"
                className="w-[85px] h-6 mb-2"
              />
            )}
            <div className="font-secondary font-semibold text-lg">
              Katherine Lindsay
            </div>
          </div>
        </a>
        <a
          href="https://www.trustpilot.com/users/60945782a8fc63001b6f847b"
          className={`cardTwo ${
            backgroundCol ? `${backgroundCol} text-black` : "bg-boxColor"
          } rounded-2xl lg:w-22REM w-full p-6 relative mb-6 lg:mb-0 shadow-lg`}
          target="_blank"
        >
          <p className=" font-opensans font-semibold text-base">
            "Tomedes has been nothing but kind, knowledgeable and efficient!"
          </p>
          <p className="mt-6 font-opensans  font-light text-sm mb-28 tracking-wider leading-[22px]">
            Tomedes has been nothing but kind, knowledgeable and
            efficient!...Everything was seamless! I trusted Tomedes so much
            they’re now working...with an even bigger project: interpreting a
            multi-language Zoom meeting!
          </p>
          <div className="name mt-12 absolute bottom-0 mb-4 pb-6">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/1.png"
              alt=""
              className="w-9 h-9 mb-2"
            />
            <div className="font-secondary font-semibold text-lg">
              Ashrena Ali
            </div>
          </div>
        </a>
        <a
          href="https://www.trustpilot.com/reviews/61b11ffd2b49257fe29a48fa"
          className={`cardThree ${
            backgroundCol ? `${backgroundCol} text-black` : "bg-boxColor"
          } rounded-2xl p-6 w-full relative lg:w-22REM shadow-lg`}
          target="_blank"
        >
          <p className=" font-opensans font-semibold text-base">
            "Tomedes is a high quality translation provider."
          </p>
          <p className="mt-6 font-opensans  font-light text-sm mb-28 tracking-wider leading-[22px]">
            I am very satisfied with Tomedes's work ethic, organization,
            attention to detail, and customer support. They are quick to respond
            to feedback which was beneficial to the improvement of our
            localization program. We highly recommend Tomedes for localization
            projects in multiple languages.
          </p>
          <div className="name absolute bottom-0 mb-4 pb-6">
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/code.svg"
              alt="code"
              className="w-9 h-9 mb-2"
            />
            <div className="font-secondary font-semibold text-lg">
              Jorge Castro
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
