import GetInTouch from "components/getInTouch";
import Reviews from "components/homepage/reviews";
import TranslateAgency from "components/homepage/translateAgency";
import WhatChooseUs from "components/homepage/whyChooseUs";
import LocationBanner from "components/locationBanner";
import React from "react";
import ContentService from "./content";

export default function ContentWritingServices() {
  return (
    <div>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="Content writing services"
        content="Get top quality content writing services. Choose talented writers of Tomedes, an online content creation company, and get the quality content you need for your blog or website. Get a quote now!"
        formOrangeHead="Get in touch  "
        formWhiteHead=" with our translation company"
      />
      <TranslateAgency
        head="Trusted by Satisfied Clients Worldwide"
        colorHead="95,000+"
        coloR="text-orange"
        content="At Tomedes, the client is always our priority, and client happiness drives our business ethic. We have a satisfaction rate of 97% because the customer always comes first."
      />
      <ContentService />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <Reviews backgroundCol="bg-midBlue" />
      <GetInTouch />
    </div>
  );
}
