import React from "react";
import CustomerSvg from "assets/images/customer_service.svg";
import BadgeSvg from "assets/images/business.svg";
import ClockSvg from "assets/images/fast_delivery.svg";
import SuitCaseSvg from "assets/images/guarantee.svg";

export default function QuoteWhatChooseUs({
  backgroundCol,
  LanguageName,
  textColor,
  secondaryBg,
}) {
  return (
    <section
      className={`${
        backgroundCol ? backgroundCol : "bg-midBlue"
      } pt-24 pb-16 relative`}
    >
      <div className="container mx-auto px-4 lg:w-9/12">
        <div className="">
          {LanguageName ? (
            <h2
              className={`text-[35px] text-center ${
                textColor ? textColor : "text-white"
              }  font-secondary font-bold leading-10 uppercase pl-4`}
            >
              <span className="text-[#042C7F]">WHY &nbsp;</span>CHOOSE TOMEDES{" "}
              {LanguageName} TRANSLATION SERVICE
            </h2>
          ) : (
            <h2
              className={` text-[35px] text-center ${
                textColor ? textColor : "text-white"
              } font-secondary font-bold leading-10 uppercase pl-4`}
            >
              <span className="text-[#042C7F]">WHY &nbsp;</span>CHOOSE US ?
            </h2>
          )}
        </div>

        <div className="grid grid-cols-1 place-items-center place-content-center py-8 gap-10 md:grid-cols-2 md:gap-4 md:max-w-xl mx-auto lg:flex lg:flex-row lg:max-w-screen-xl lg:gap-0 lg:space-x-4 lg:items-stretch">
          <div
            className={`${
              secondaryBg
                ? `${secondaryBg} text-black border-[#777777]`
                : "bg-darkBlue text-white border-quotetext-200"
            }  border  rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1`}
          >
            <img
              src={CustomerSvg}
              alt="loading..."
              className="m-auto h-[55px] object-contain"
            />

            <h3 className=" text-md py-4 font-semibold font-secondary min-h-[80px]">
              24/7 Customer Service
            </h3>
            <ul className="text-left list-disc text-sm font-opensans">
              <li>Personal project manager</li>
              <li>Support and assistance via email, phone and chat</li>
            </ul>
          </div>

          <div
            className={`${
              secondaryBg
                ? `${secondaryBg} text-black border-[#777777]`
                : "bg-darkBlue text-white border-quotetext-200"
            } border border-quotetext-200 rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1`}
          >
            <img
              src={BadgeSvg}
              alt="loading..."
              className="m-auto h-[55px] object-contain"
            />
            <h3 className=" text-md py-4 font-secondary font-semibold min-h-[80px]">
              1-year guarantee
            </h3>
            <ul className="text-left list-disc text-sm font-opensans">
              <li>Post-completion project support</li>
              <li>Comprehensive quality assurance</li>
            </ul>
          </div>

          <div
            className={` ${
              secondaryBg
                ? `${secondaryBg} text-black border-[#777777]`
                : "bg-darkBlue text-white border-quotetext-200"
            } border border-quotetext-200 rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1`}
          >
            <img
              src={ClockSvg}
              alt="loading..."
              className="m-auto h-[55px] object-contain"
            />

            <h3 className=" text-md py-4 font-secondary  font-semibold min-h-[80px]">
              Fast Delivery
            </h3>
            <ul className="text-left  list-disc text-sm font-opensans">
              <li>Availability for urgent orders</li>
              <li>
                Working around the clock to provide the best, fastest service
              </li>
            </ul>
          </div>

          <div
            className={`${
              secondaryBg
                ? `${secondaryBg} text-black border-[#777777]`
                : "bg-darkBlue text-white border-quotetext-200"
            } border border-quotetext-200 rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1`}
          >
            <img
              src={SuitCaseSvg}
              alt="loading..."
              className="m-auto h-[55px] object-contain"
            />
            <h3 className=" text-md py-4 font-secondary font-semibold min-h-[80px]">
              95,000 Business Customers
            </h3>
            <ul className="text-left  list-disc text-sm font-opensans">
              <li>Fortune 500 companies trust Tomedes translation agency</li>
              <li>Official and selected Google and Android partner</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
