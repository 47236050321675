import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";

import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import RedExclamation from "assets/redexclon.svg";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import { Link } from "gatsby";

const ContactForm = ({
  open,
  setOpen,
  phonenumber,
  setPhonenumber,
  status,
  mutateAsync,
  location,
  captchaVisible,
  setCaptchaVisible,
  lebeltextColour,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (token) {
    const postData1 = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      country_id: data.country_id,
      // country_code: countrycode,
      phone: phonenumber,
      company: data.company,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };

    mutateAsync(postData).then((_) => {
      setOpen(true);
      reset();
      setPhonenumber("");
    });
    // } else {
    //   alert("Please verify captcha");
    // }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className={`text-[#00173A] max-w-[456px] gap-y-8 text-${lebeltextColour}`}
      >
        {/* <div className="flex sm:flex-row flex-col gap-x-4 gap-y-8"> */}
        <div className="">
          <label
            htmlFor=""
            className="relative text-sm font-semibold font-opensans flex flex-col"
          >
            <span>
              Name <span className="text-red">*</span>
            </span>
            <input
              onClick={() => setCaptchaVisible(true)}
              type="text"
              placeholder="Your name"
              className={`border rounded-md border-[#BBBBBB] h-[45px] text-black  pl-3 ${
                errors.first_name
                  ? "border-red outline-none focus:border-[#ff0000]"
                  : ""
              }`}
              name="first_name"
              {...register("first_name", { required: true })}
            />
            {errors.first_name && (
              <img
                src={RedExclamation}
                alt="Error"
                className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
              />
            )}
            {errors.first_name && (
              <span className="text-red">{errorMessage}</span>
            )}
          </label>
        </div>
        <div className="my-6">
          <label
            htmlFor=""
            className="relative text-sm font-semibold font-opensans flex flex-col"
          >
            <span>
              Email address<span className="text-red">*</span>
            </span>
            <input
              type="email"
              placeholder="Your email address"
              className={`border rounded-md border-[#BBBBBB] text-black  h-[45px] pl-3 ${
                errors.email
                  ? "border-red outline-none focus:border-[#ff0000]"
                  : ""
              }`}
              name="email"
              {...register("email", {
                required: true,
                pattern: patternEmail,
              })}
            />
            {errors.email && (
              <img
                src={RedExclamation}
                alt="Error"
                className="absolute right-2 top-[56%] transform -translate-y-1/2 w-4 h-4"
              />
            )}
            {errors.email && (
              <span className="text-red">{errorMessageEmail}</span>
            )}
          </label>
        </div>
        <div className="">
          <label
            htmlFor=""
            className="text-sm font-semibold font-opensans"
            required
          >
            Phone number
            <PhoneInput
              country={"us"}
              value={phonenumber}
              placeholder=""
              onChange={(value) => setPhonenumber("+" + value)}
              className="border rounded-md border-[#BBBBBB] text-black h-[45px]"
            />
          </label>
        </div>

        {/* <label
                  htmlFor=""
                  className="flex flex-col text-sm font-semibold font-opensans"
                >
                  Company
                  <input
                    type="text"
                    className="border rounded-md border-[#BBBBBB] h-[45px] pl-3"
                    name="company"
                    {...register("company")}
                  />
                </label> */}
        <div className="my-6">
          <label
            htmlFor=""
            className="flex flex-col text-sm font-semibold font-opensans"
          >
            Message
            <textarea
              placeholder="Tell us more about your needs"
              type="text"
              className="border rounded-md border-[#BBBBBB] text-black h-[80px] pl-3 pt-3"
              name="message"
              {...register("message")}
            />
            <p className="text-[#91979F] font-opensans text-sm pt-4 sm:w-full w-[90%]">
              *By submitting this, you agree to Tomedes'
              <Link to="/privacy" className="text-orange">
                {" "}
                Privacy Policy.
              </Link>
            </p>
          </label>
        </div>
        {/* {captchaVisible && (
                  <HCaptcha
                    sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                    onVerify={handleVerify}
                    ref={captchaRef}
                  />
                )} */}
        <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        <button className="w-[189px] h-[55px] rounded-full bg-orange hover:bg-newOrange-100 text-white font-opensans font-semibold text-lg">
          {status === "loading" ? <Loader /> : "SUBMIT"}
        </button>
      </form>
    </>
  );
};
export default ContactForm;
