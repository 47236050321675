import Layout from "components/layout";
import ContentWritingServices from "components/reusableComponents/content-writing-services";
import SEO from "components/seo";
import React from "react";

function index() {
  const title = "Content Writing Services | Tomedes";
  const description = "Get top quality content writing services. Choose talented writers of Tomedes, an online content creation company, and get the quality content you need for your blog or website. Get a quote now!";
  const keywords = "Content Writing Services | Tomedes";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/content-writing-services"
      />
      <ContentWritingServices />
    </Layout>
  );
}

export default index;
